import { useNavigate, useParams } from "react-router";
import InvoiceDetails from "./components/ClientDetails/BillingTab/InvoiceDetails";
import { ArrowLeft } from "lucide-react";

const InvoiceDetailsById = () => {
  const { clientId, invoiceId } = useParams();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-4">
      <button
        onClick={handleGoBack}
        className="flex items-center hover:opacity-75"
      >
        <ArrowLeft />
      </button>
      <InvoiceDetails
        clientId={Number(clientId)}
        invoiceId={Number(invoiceId)}
        onDelete={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};

export default InvoiceDetailsById;
