import { t } from "@lingui/core/macro";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { Flex, Pagination, Tabs } from "@mantine/core";

import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useAdminStore } from "./admin.store";
import { ClientDetails } from "./components/ClientDetails/ClientDetails";
import { ClientsTable } from "./components/ClientsTable";
import { FilterRow } from "./components/FilterRow";
import { useClients } from "./hooks/useClients";
import { EracuniIndex } from "./Eracuni";
import PricingTiers from "./PricingTiers";
import React, { useEffect, useState } from "react";
import PricingTierEdit from "./PricingTiers/PricingTierEdit";
import { useDebouncedCallback } from "@mantine/hooks";
import { LimePageHeader } from "@/Components/LimePageHeader";
import InvoicesPage from "./InvoicesPage";
import InvoiceDetailsById from "./InvoiceDetailsById";

export const LimeAdmin = () => {
  const { data, handleChangeSalesPersonForClient, isLoading } = useClients();

  const { setPage, page, setSearchQuery } = useAdminStore((state) => state);

  const [query, setQuery] = useState("");

  const handleSearch = useDebouncedCallback(() => {
    setSearchQuery(query);
  }, 500);

  useEffect(() => {
    handleSearch();
  }, [query]);

  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(() => {
    const path = location.pathname.split("/").pop();
    return path || "clients";
  });

  const handleTabChange = (value: string | null) => {
    if (value) {
      if (value === "clients") {
        setTabValue("clients");
        navigate("/dashboard/admin");
        return;
      }

      setTabValue(value);
      navigate(`/dashboard/admin/${value}`);
    }
  };

  return (
    <>
      <LimePageHeader title={t`Admin`} px={14} />
      <Tabs
        defaultValue="clients"
        value={tabValue}
        onChange={handleTabChange}
        keepMounted={false}
        orientation="horizontal"
      >
        <Tabs.List>
          <Tabs.Tab value="clients">{t`Clienti`}</Tabs.Tab>
          <Tabs.Tab value="pricing-tiers">{t`Cenilni razredi`}</Tabs.Tab>
          <Tabs.Tab value="invoices">{t`Računi`}</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Routes>
        <Route
          path="/"
          element={
            <Flex
              gap={10}
              direction={"column"}
              top={0}
              left={0}
              right={0}
              style={{ zIndex: 1 }}
            >
              <div className="px-4">
                <FilterRow
                  industries={data?.industries || []}
                  salesPeople={data?.salesPeople || []}
                  pricingTiers={data?.pricingTiers || []}
                  query={query}
                  setQuery={setQuery}
                ></FilterRow>
              </div>
              <ClientsTable
                data={data}
                handleChangeSalesPersonForClient={
                  handleChangeSalesPersonForClient
                }
                isLoading={isLoading}
              ></ClientsTable>

              <div className="px-4">
                <Pagination
                  w={"100%"}
                  total={data?.totalPages ?? 10}
                  value={page}
                  onChange={setPage}
                  size={"xs"}
                  siblings={1}
                  boundaries={1}
                ></Pagination>
              </div>
            </Flex>
          }
        ></Route>
        <Route
          path="client/:id/*"
          element={<ClientDetails></ClientDetails>}
        ></Route>

        <Route path="eracuni/*" element={<EracuniIndex></EracuniIndex>}></Route>
        <Route
          path="pricing-tiers"
          element={<PricingTiers></PricingTiers>}
        ></Route>
        <Route
          path="pricing-tiers/:pricingTierId"
          element={<PricingTierEdit></PricingTierEdit>}
        ></Route>
        <Route path="invoices" element={<InvoicesPage></InvoicesPage>}></Route>
        <Route
          path="invoices/:clientId/:invoiceId"
          element={<InvoiceDetailsById></InvoiceDetailsById>}
        ></Route>
      </Routes>
    </>
  );
};
