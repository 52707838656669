import { api } from "@/lib/api-client";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Pagination,
  Link,
  Button,
} from "@heroui/react";
import { Eye } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router";

const InvoicesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = Number(searchParams.get("page") || "1");
  const perPage = Number(searchParams.get("perPage") || "10");
  const sortOrder = (searchParams.get("sortOrder") || "desc") as "asc" | "desc";

  const { data, isFetching, isError, isSuccess } = api.admin.useGetInvoices({
    page,
    perPage,
    sortOrder,
  });

  const handlePageChange = (newPage: number) => {
    setSearchParams((prev: URLSearchParams) => {
      prev.set("page", newPage.toString());
      return prev;
    });
  };

  if (isFetching) return <div>Loading...</div>;

  if (!isSuccess) return <div>Error</div>;

  return (
    <div className="p-4">
      <Table removeWrapper>
        <TableHeader>
          <TableColumn>Client id</TableColumn>
          <TableColumn>Client name</TableColumn>
          <TableColumn>Eracuni documentId</TableColumn>
          <TableColumn>Datum</TableColumn>
          <TableColumn>Cena</TableColumn>
          <TableColumn>Odpri račun</TableColumn>
        </TableHeader>
        <TableBody>
          {data.invoices.map((invoice) => (
            <TableRow key={invoice.id} className="hover:bg-gray-100">
              <TableCell>
                <Link href={`/dashboard/admin/client/${invoice.clientId}`}>
                  {invoice.clientId}
                </Link>
              </TableCell>
              <TableCell>{invoice.client}</TableCell>
              <TableCell>{invoice.externalInvoiceId}</TableCell>
              <TableCell>{invoice.dateCreated}</TableCell>
              <TableCell>{invoice.priceTotal} €</TableCell>
              <TableCell>
                <Button
                  isIconOnly
                  onPress={() => {
                    navigate(
                      `/dashboard/admin/invoices/${invoice.clientId}/${invoice.id}`,
                    );
                  }}
                >
                  <Eye />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        page={page}
        onChange={handlePageChange}
        total={Math.ceil(data.totalCount / perPage)}
        boundaries={1}
        siblings={1}
      />
    </div>
  );
};

export default InvoicesPage;
